<template>
  <div>
      <busca-beneficiario></busca-beneficiario>
  </div>
</template>

<script>
export default {
  components: {
    "busca-beneficiario": require("../components/BuscaBeneficiario.vue").default
  }
};
</script>


<template>
  <div>
    <div class="mt-5 row">
      <div class="mx-auto col-lg-9 col-xs-12">
        <b-breadcrumb :items="items"></b-breadcrumb>

        <div class="card p-3 mb-3">
          <div class="card-body">
            <!-- primeiro caso, NAO consegui recuperar os dados -->
            <div v-if="erroRecuperaDadosCidadao">
              <!-- se for uma resposta esperada da API -->
              <div v-if="erroAPI">
                <msg-dados-invalidos :bRetornoComDados="true" :titulo="tituloDadosInvalidos"></msg-dados-invalidos>
                <div v-if="temBeneficioExtra" class="alert alert-warning">
                  <p>
                    Seus dados não foram encontrados no cadastro de famílias
                    vulneráveis, mas foram localizados no cadastro de famílias
                    de estudantes matriculados na Rede Municipal de Ensino.
                    <br />Acesse
                    <a href="https://cestaestudante.pbh.gov.br" target="_blank"
                      rel="noopener noreferrer">cestaestudante.pbh.gov.br</a>, informe seus dados e realize a consulta
                    para a retirada
                    do benefício.
                    <br />
                  </p>
                </div>
              </div>
              <div v-else>
                <!-- caso de logins insconsistentes em funcao de problemas no banco -->
                <msg-login-inconsistente v-if="erroLoginInconsistente"></msg-login-inconsistente>
                <!-- se for uma resposta inesperada (rede, banco etc) -->
                <div v-else class="alert alert-danger">
                  {{ erroRecuperaDadosCidadao }}
                </div>
              </div>
            </div>

            <!-- caso contrario, consegui recuperar os dados -->
            <div v-else>
              <!-- se o usuario considera que os dados NAO conferem -->
              <msg-dados-invalidos v-if="resposta == 'nao'"></msg-dados-invalidos>

              <!-- se os dados foram recuperados e o usuario 1. ainda nao respondeu a pergunta, ou 2. respondeu positivamente -->
              <div v-if="dadosCidadao && resposta != 'nao'">
                <p>
                  <strong>CPF:</strong>
                  {{ dadosCidadao.cpf_resp | formataCpf }}
                </p>
                <p>
                  <strong>Nome:</strong>
                  {{ dadosCidadao.nome_responsavel }}
                </p>

                <!-- se o usuario ainda nao respondeu a pergunta -->
                <div v-if="resposta == null">
                  As informações conferem?
                  <div class="row">
                    <div class="col">
                      <div class="d-grid">
                        <button class="btn btn-success" @click="responder('sim')">
                          Sim
                        </button>
                      </div>
                    </div>
                    <div class="col">
                      <div class="d-grid">
                        <button class="btn btn-danger" @click="responder('nao')">
                          Não
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- se o usuario respondeu positivamente -->
                <div v-else>
                  <!-- erro inesperado ao recuperar dados do supermercado (rede, banco etc) -->
                  <div v-if="erroRecuperaDadosSupermercado" class="alert alert-danger">
                    {{ erroRecuperaDadosSupermercado }}
                  </div>

                  <div v-if="resposta == 'sim' && dadosSupermercado" class="alert alert-success">
                    Você tem direito a <strong>Cesta Tipo
                      {{ dadosSupermercado.tipo_produto }}</strong>. <br />{{ strDiaRetirada }} dia
                    <strong>{{ dadosSupermercado.data_retirada }}</strong>, no período da
                    <strong id="turno">{{ dadosSupermercado.turno }}</strong>, dirija-se ao supermercado
                    <strong>{{ dadosSupermercado.nome_exibicao }}</strong>
                    (
                    <strong>{{ dadosSupermercado.endereco_exibicao }}</strong>
                    ) para retirada do seu benefício, de posse do seu CPF
                    e do voucher (impresso, no dispositivo eletrônico ou código de acesso anotado):
                    <div class="my-4 card mx-auto" style="width: 70%">
                      <div class="card-body">
                        <h1 class="text-center">
                          {{ dadosSupermercado.voucher }}
                        </h1>
                      </div>
                    </div>
                  </div>

                  <div v-if="temBeneficioExtra" class="alert alert-warning">
                    <p>
                      <span v-if="dadosSupermercado.tipo_produto ===
                        'kit de produtos de higiene'
                        ">
                        A sua <strong>cesta básica</strong> já foi concedida
                        através do Cesta Estudante.
                      </span>
                      <span v-else>
                        Você também tem direito à Cesta Básica.
                      </span>
                      Acesse
                      <a href="https://cestaestudante.pbh.gov.br" target="_blank"
                        rel="noopener noreferrer">cestaestudante.pbh.gov.br</a>, informe seus dados e realize a consulta
                      para a retirada
                      do benefício.
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!------------- botoes contato   ------------------------->
    <div class="container" v-if="erroAPI || erroLoginInconsistente || resposta === 'nao'">
      <div class="row my-3 justify-content-center">
        <!-- <botoes-contato class="col text-center"></botoes-contato> -->
        <div class="col">
          <div class="row justify-content-center">
            <div class="col-4 col-lg-2">
              <a style="font-size: small" :href="linkDuvidas">Tire suas dúvidas</a>
            </div>
            <div class="col-4 col-lg-2">
              <a style="font-size: small" :href="linkAjuda">Precisa de ajuda?</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import constants from "../constants";
import moment from "moment";

export default {
  components: {
    "msg-dados-invalidos": require("./mensagens/_MSGDadosInvalidos").default,
    "msg-login-inconsistente": require("./mensagens/_MSGLoginInconsistente")
      .default,
    // "botoes-contato": require("./layout/BotoesContato").default,
  },
  data() {
    return {
      resultado: {},
      resposta: null,
      dadosCidadao: null,
      dadosSupermercado: null,
      mesReferencia: "",
      anoReferencia: "",
      erroRecuperaDadosSupermercado: null,
      erroRecuperaDadosCidadao: null,
      erroAPI: false,
      erroLoginInconsistente: false,
      temBeneficioExtra: false,
      tituloDadosInvalidos: "",
      items: [
        { text: "Pesquisa de dados", to: { name: "home" } },
        { text: "Informações para a retirada da cesta", active: true },
      ],
    };
  },
  computed: {
    http() {
      return this.$store.getters.http;
    },
    cpf() {
      return this.$store.getters.cpf;
    },
    nome() {
      return this.$store.getters.nome;
    },
    linkDuvidas() {
      const url = this.$env.LINK_DUVIDAS_URL || constants.PBH_URL
      return url;
    },
    linkAjuda() {
      const url = this.$env.LINK_AJUDA_URL || constants.PBH_URL
      return url;
    },
    strDiaRetirada() {
      let dataRetirada = this.dadosSupermercado?.data_retirada;
      if (!dataRetirada) return "No";
      const dataRetiradaObj = moment(dataRetirada, "DD/MM/YYYY");
      const dataHoje = moment(Date());
      return (dataHoje.isSameOrBefore(dataRetiradaObj)) ? "No" : "A partir do";
    }
  },
  methods: {
    resetErros() {
      this.erroRecuperaDadosCidadao = null;
      this.erroRecuperaDadosSupermercado = null;
      this.erroAPI = false;
    },
    async responder(resposta) {
      this.resposta = resposta;
      this.$store.commit("setLoading", true);

      if (resposta === "sim") {
        try {
          this.$Progress.start();
          this.resetErros();

          let { data } = await this.http.get(`/busca-voucher`, {
            headers: {
              Authorization: `${this.dadosCidadao.id}:${this.dadosCidadao.uuid}:${this.cpf}`,
            },
          });
          if (data.erro === "true") {
            this.erroAPI = true;
            if (typeof data.message === 'object') {
              const errsVals = (Object.values(data.message)).flat();
              let errsStr = errsVals.join('<br />');
              this.erroRecuperaDadosSupermercado = errsStr;

            } else {
              this.erroRecuperaDadosSupermercado = data.message;
            }
            this.$Progress.fail();
          } else {
            this.dadosSupermercado = data.dados;
            this.mesReferencia = data.dados.mes;
            this.anoReferencia = data.dados.ano;
            this.flag_visualizou = data.dados.flag_visualizou;
            if (data.beneficioExtra === "true") {
              this.temBeneficioExtra = true;
            }
            this.$Progress.finish();
          }
          this.$store.commit("setLoading", false);
        } catch (error) {
          this.erroRecuperaDadosSupermercado =
            "Falha ao recuperar dados. Tente novamente em instantes.";
          this.$store.commit("setLoading", false);
          this.$Progress.fail();
        }
      } else {
        this.$store.commit("setLoading", false);
      }
    },
    async buscaAlunos() {
      this.resetErros();
      this.$store.commit("setLoading", true);

      if (this.cpf && this.nome) {
        try {
          this.$Progress.start();
          let { data } = await this.http.get(
            `${this.$env.VUE_APP_BACKEND_URL}/busca-dados/${this.cpf}/${this.nome}`,
            {
              headers: { Authorization: `Bearer ${this.$store.getters.token}` },
            }
          );

          if (data.erro === "true") {
            console.log(data);
            if (parseInt(data.code) >= 3 && parseInt(data.code) < 6) {
              this.erroAPI = true;
              if (parseInt(data.code) === 4) {
                this.tituloDadosInvalidos = 'O CPF consultado não consta entre os beneficiários desta ação.';
                if (data.beneficioExtra === "true") {
                  this.temBeneficioExtra = true;
                }
              } else if (parseInt(data.code) === 3) {
                this.tituloDadosInvalidos = 'Os dados informados não pertencem ao mesmo(a) cidadão(ã). Verifique e insira novamente as informações.';
              }
            }
            if (parseInt(data.code) == 99) {
              this.erroLoginInconsistente = true;
            }
            if (typeof data.message === 'object') {
              const errsVals = (Object.values(data.message)).flat();
              let errsStr = errsVals.join('<br />');
              this.erroRecuperaDadosCidadao = errsStr;

            } else {
              this.erroRecuperaDadosCidadao = data.message;
            }

            this.$Progress.fail();
          } else {
            this.dadosCidadao = data.dados;
            this.$Progress.finish();
          }

          this.$store.commit("setLoading", false);
        } catch (e) {
          this.erroRecuperaDadosCidadao =
            "Falha ao recuperar dados. Tente novamente em instantes.";

          this.$store.commit("setLoading", false);
          this.$Progress.fail();
        }
      } else {
        this.$store.commit("setLoading", false);
        this.$router.push("/");
      }
    },
  },
  async mounted() {
    this.$store.commit("registraRetornoComDados", false);
    this.buscaAlunos();
  },
};
</script>

<style scoped>
.error {
  color: #dc3545;
}

#turno {
  text-transform: uppercase;
}
</style>

<template>
  <div class="alert alert-warning">
    <h3>{{ titulo }}</h3>
    <p>
      A oferta de uma cesta de alimentos básicos, nos períodos de férias escolares,
      é destinada a estudantes matriculados na Rede Municipal de Educação de Belo Horizonte,
      residentes no município, cujas famílias estão em situação de pobreza e extrema pobreza*,
      de acordo com dados do Cadastro Único para Programas Sociais (CadÚnico).
      <br />*faixa de renda de R$0,00 (zero reais) a R$218,00 (duzentos e dezoito reais) por pessoa por mês.
    </p>
    <p>
      Se ainda assim precisar de atendimento, acesse uma das opções de suporte.
    </p>
    <div class="d-block d-md-none">
      <botao-duvidas></botao-duvidas>
      <div class="d-grid">
        <button class="btn btn-secondary" @click="responder()">Retornar</button>
      </div>
    </div>
    <div class="d-none d-md-block">
      <div class="row">
        <botao-duvidas class="col-6"></botao-duvidas>
        <div class="col">
          <div class="d-grid">
            <button class="btn btn-secondary" @click="responder()">
              Retornar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "msg-dados-invalidos",
  components: {
    "botao-duvidas": require("../layout/BotaoDuvidas").default,
  },
  props: {
    bRetornoComDados: { type: Boolean, default: false },
    titulo: { type: String, default: 'Dados não encontrados' },
  },
  methods: {
    responder() {
      if (this.bRetornoComDados) {
        this.$store.commit("registraRetornoComDados", true);
      } else {
        this.$store.dispatch("flushDados");
      }
      this.$router.push("/");
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <div class="mt-5 row">
      <div class="mx-auto col-lg-9 col-xs-12">
        <b-breadcrumb :items="items"></b-breadcrumb>
        <!-- <div class="row align-center">
          <div class="col-xm-11 col-md-8 mb-3"></div>
          <div class="col mb-3">
            <botao-duvidas></botao-duvidas>
          </div>
        </div> -->

        <div class="alert alert-warning">
          <p class="text-center"><strong>ATENÇÃO</strong></p>
          <p>
            A oferta de uma cesta de alimentos básicos, nos períodos de férias escolares,
            é destinada a estudantes matriculados na Rede Municipal de Educação de Belo Horizonte,
            residentes no município, cujas famílias estão em situação de pobreza e extrema pobreza*,
            de acordo com dados do Cadastro Único para Programas Sociais (CadÚnico).
            <br />*faixa de renda de R$0,00 (zero reais) a R$218,00 (duzentos e dezoito reais) por pessoa por mês.
          </p>
        </div>

        <div class="alert alert-warning" v-if="error">{{ error }}</div>
        <div class="card">
          <div class="card-body p-3">
            <div class="row py-2">
              <div class="col">
                <label for="cpf">CPF do responsável pela matrícula:</label>
              </div>
            </div>
            <div class="row py-2">
              <div class="col-11">
                <the-mask id="cpf" name="cpf" pattern="[0-9\.\-]*" inputmode="numeric" mask="###.###.###-##" type="text"
                  :masked="false" placeholder="nnn.nnn.nnn-nn"
                  :class="`form-control` + ($v.cpf.$error ? ` is-invalid` : ``)" v-model="$v.cpf.$model"
                  @change="$v.cpf.$touch()" @keyup.enter="entrar" autofocus></the-mask>
                <div class="error" v-if="$v.cpf.$error">
                  <div v-if="!$v.cpf.required">
                    <small>Este campo é obrigatório.</small>
                  </div>
                  <div v-if="!$v.cpf.numeric">
                    <small>Este campo aceita apenas valores numéricos.</small>
                  </div>
                  <div v-if="!$v.cpf.isCpf">
                    <small>CPF inválido.</small>
                  </div>
                </div>
              </div>
              <div class="col-1">
                <a href="#" class="py-2 text-info" data-bs-toggle="tooltip" data-bs-placement="right"
                  title="Insira aqui o seu CPF.">
                  <font-awesome-icon :icon="['fas', 'fa-question-circle']" />
                </a>
              </div>
            </div>
            <div class="row py-2">
              <div class="col">
                <label for="nome">Primeiro nome do responsável pela matrícula:</label>
              </div>
            </div>
            <div class="row py-2">
              <div class="col-11">
                <input id="nome" type="text" :class="`form-control` + ($v.nome.$error ? ` is-invalid` : ``)
                  " name="nome" placeholder="Apenas letras" v-model.trim="$v.nome.$model" @change="$v.nome.$touch()"
                  @keyup.enter="entrar" />
                <div class="error" v-if="$v.nome.$error">
                  <div v-if="!$v.nome.required">
                    <small>Este campo é obrigatório.</small>
                  </div>
                  <div v-if="!$v.nome.minLength">
                    <small>Este campo deve possuir pelo menos 3 letras.</small>
                  </div>
                  <div v-if="!$v.nome.alpha">
                    <small>Este campo deve ser preenchido apenas com letras.</small>
                  </div>
                </div>
              </div>
              <div class="col-1">
                <a href="#" class="py-2 text-info" data-bs-toggle="tooltip" data-bs-placement="right"
                  title="Primeiro nome do detentor do CPF informado.">
                  <font-awesome-icon :icon="['fas', 'fa-question-circle']" />
                </a>
              </div>
            </div>
            <div class="row py-2">
              <div class="col">
                <div class="d-grid">
                  <button type="submit" @click="entrar" class="btn btn-primary">
                    Entrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!------------- botoes contato   ------------------------->
    <div class="container">
      <div class="row my-3 justify-content-center">
        <!-- <botoes-contato class="col text-center"></botoes-contato> -->
        <div class="col">
          <div class="row justify-content-center">
            <div class="col-4 col-lg-2">
              <a style="font-size: small" :href="linkDuvidas">Tire suas dúvidas</a>
            </div>
            <div class="col-4 col-lg-2">
              <a style="font-size: small" :href="linkAjuda">Precisa de ajuda?</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validaCPF } from "../validations/index";
import { TheMask } from "vue-the-mask";
import {
  required,
  minLength,
  numeric,
  alpha,
  // helpers
} from "vuelidate/lib/validators";
import { Tooltip } from "bootstrap";
import constants from "../constants";

export default {
  components: {
    TheMask,
    // "botoes-contato": require("./layout/BotoesContato").default,
    // "botao-duvidas": require("./layout/BotaoDuvidas").default,
  },
  data() {
    return {
      cpf: "",
      nome: "",
      validCaptcha: false,
      error: null,
      items: [{ text: "Pesquisa de dados", active: true }],
    };
  },
  validations: {
    cpf: {
      required,
      numeric,
      isCpf: (cpf) => validaCPF(cpf),
    },
    nome: {
      required,
      minLength: minLength(3),
      alpha,
    },
  },
  computed: {
    http() {
      return this.$store.getters.http;
    },
    token() {
      return this.$store.getters.token;
    },
    linkDuvidas() {
      const url = this.$env.LINK_DUVIDAS_URL || constants.PBH_URL
      return url;
    },
    linkAjuda() {
      const url = this.$env.LINK_AJUDA_URL || constants.PBH_URL
      return url;
    }
  },
  watch: {},
  methods: {
    async entrar() {
      this.error = null;
      this.$store.commit("setLoading", true);

      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$Progress.start();

        try {
          await this.$store.dispatch("gravaDadosForm", {
            cpf: this.cpf,
            nome: this.nome,
          });
          this.$router.push("info");
          this.$store.commit("setLoading", false);
          this.$Progress.finish();
        } catch (e) {
          this.$store.commit("setLoading", false);
          this.$Progress.fail();
          this.error = e;
        }
      } else {
        this.$store.commit("setLoading", false);
      }
    },
    cestaEstudante() {
      const win = window.open("https://cestaestudante.pbh.gov.br", "_blank");
      win.focus();
    },
  },
  async mounted() {
    if (this.$store.getters.retornoComDados) {
      this.$v.cpf.$model = this.$store.getters.cpf;
      this.$v.nome.$model = this.$store.getters.nome;
    } else {
      this.$store.dispatch("flushDados");
    }
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    // eslint-disable-next-line no-unused-vars
    const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  },
};
</script>

<style scoped>
.error {
  color: #dc3545;
}
</style>

<template>
  <div>
      <info-supermercado></info-supermercado>
  </div>
</template>

<script>
export default {
  components: {
    "info-supermercado": require("../components/InfoSupermercado.vue").default
  }
};
</script>


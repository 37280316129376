<template>
  <div class="alert alert-warning">
    <h3>Atenção</h3>
    <p>
      Consulta já realizada para o CPF informado.
      <br />Caso não tenha guardado as informações acesse o chat para
      orientações.
    </p>
    <div class="d-grid">
      <button class="btn btn-secondary" @click="responder()">Retornar</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "msg-login-inconsistente",
  props: {
    bRetornoComDados: { type: Boolean, default: false },
  },
  methods: {
    responder() {
      if (this.bRetornoComDados) {
        this.$store.commit("registraRetornoComDados", true);
      } else {
        this.$store.dispatch("flushDados");
      }
      this.$router.push("/");
    },
  },
};
</script>

<style></style>

<template>
  <div class="d-grid">
    <button
      class="btn btn-outline-info mb-1 px-4"
      @click="duvidas"
      title="Tire suas dúvidas aqui"
    >
      <div class="text-center">
        <font-awesome-icon
          class="me-2"
          :icon="['fas', 'question-circle']"
          @click="duvidas"
          title="Informações importantes"
        />
        <span>Tire suas dúvidas!</span>
      </div>
    </button>
  </div>
</template>

<script>
import constants from "../../constants";

export default {
  methods: {
    duvidas() {
      const url = this.$env.MANUTENCAO_REDIRECT_URL || constants.PBH_URL
      const win = window.open(url, "_blank");
      win.focus();
    },
  },
};
</script>

<style></style>
